var generic = generic || {};
var site = site || {};

(function ($) {
  site.emailSignup = {
    templateContainer: $(),

    initEmailSignup: function (context) {
      var $emailContainerNodes = this.templateContainer;
      var $gdprLink = $('.site-email-signup__gdpr-link', context);

      if (!$emailContainerNodes.length) {
        return null;
      }

      $(document)
        .not($gdprLink)
        .off('touchstart')
        .on('touchstart', function () {
          $gdprLink.trigger('blur');
        });

      $emailContainerNodes.each(function () {
        var $emailContainerNode = $(this);
        var $emailForm = $emailContainerNode.is('form')
          ? $emailContainerNode
          : $('form', $emailContainerNode);
        var $emailContent = $('.site-email-signup__email-content', $emailContainerNode);
        var $gdprLink = $('.site-email-signup__gdpr-link', $emailContainerNode);
        var $gdprCheck = $('.js-gdpr-check', $emailContainerNode);
        var $signupButton = $('.js-site-email-signup-cta .button', $emailContainerNode);
        var $smsContent = $('.site-email-signup__sms-content', $emailContainerNode);
        var $subscribeCta = $('.js-site-email-signup-cta', $emailContainerNode);
        var $emailSuccess = $('.js-email-signup__success', $emailContainerNode);
        var $emailSuccessMessage = $('.site-email-signup__success-message', $emailContainerNode);
        var $emailError = $('.js-email-signup__error', $emailContainerNode);
        var $emailInput = $('input[name="PC_EMAIL_ADDRESS"]', $emailContainerNode);
        var $mobileNumberInput = $('input[name="MOBILE_PHONE"]', $emailContainerNode);
        var $termsAndConditions = $('.js-terms-conditions', $emailContainerNode);
        var $errorMessages = $('ul.error_messages', $emailContainerNode);

        // Show terms + conditions
        $mobileNumberInput.on('focus', function () {
          $termsAndConditions.slideDown(300);
        });

        $gdprCheck.on('change', function () {
          $signupButton.toggleClass('button--disabled', !this.checked).prop('disabled', !this.checked);
        });

        var mobileNumberVal = '';
        var registerOnAutoComplete = function ($element) {
          var deferred = $.Deferred();
          var element = $element[0];

          if ($element.val() !== '') {
            deferred.resolve($element);
          }
          var hasKeyInteraction = false;

          element.addEventListener('input', function (e) {
            if (hasKeyInteraction === false) {
              deferred.resolve(e.target);
            }
          });

          element.addEventListener('keydown', function (e) {
            // If there is a keyboard interaction then we believe it is not autocomplete
            hasKeyInteraction = true;
          });

          var promise = deferred.promise();

          return promise;
        };

        if ($mobileNumberInput.length > 0) {
          registerOnAutoComplete($mobileNumberInput).then(function (element) {
            mobileNumberVal = $mobileNumberInput.val();
            if (mobileNumberVal !== '') {
              $termsAndConditions.slideDown(300);
            }
          });
        }

        $mobileNumberInput
          .on('change', function () {
            mobileNumberVal = $mobileNumberInput.val();
          })
          .on('animationstart', function (e) {
            switch (e.animationName) {
              case defaultStyles.onAutoFillStart:
                mobileNumberVal = $mobileNumberInput.val();

                return;
            }
          });
        // hide terms + conditions when clicked outside
        $(document).on('click', function (hideEvt) {
          if (!$(hideEvt.target).closest($emailForm).length && !$(hideEvt.target).is($emailForm)) {
            if ($termsAndConditions.is(':visible') && mobileNumberVal === '') {
              $termsAndConditions.slideUp(300);
            }
          }
        });

        $emailForm.once('js-email-signup').on('submit', function (submitEvt) {
          submitEvt.preventDefault();
          $emailSuccess.addClass('hidden');
          $emailError.addClass('hidden');
          $emailInput.removeClass('error');
          $mobileNumberInput.removeClass('error');

          // Transform string into array of form elements
          var params = {};

          $.each($emailForm.serializeArray(), function (index, kv) {
            params[kv.name] = kv.value.replace('undefined', '').replace('%40', '@');
          });

          // handle the sms_promotions checkbox - if its checked its being read as 'on' when serialized
          // we need a boolean value
          if ($smsContent.find('input[name="SMS_PROMOTIONS"]').prop('checked')) {
            params.SMS_PROMOTIONS = 1;
          }

          // Send the data via a json rpc call
          generic.jsonrpc.fetch({
            method: 'rpc.form',
            params: [params],
            onSuccess: function () {
              $emailContent.addClass('hidden');
              $gdprLink.addClass('hidden');
              $smsContent.addClass('hidden');
              $subscribeCta.addClass('hidden');
              $emailSuccess.html($emailSuccessMessage.val());
              $emailSuccess.removeClass('hidden');
            },
            onFailure: function (result) {
              var errorObjectsArray = result.getMessages();

              $emailError.removeClass('hidden');
              generic.showErrors(errorObjectsArray, $errorMessages, null);
            }
          });
        });
      });
    }
  };

  Drupal.behaviors.emailSignupFormV1 = {
    attach: function (context) {
      site.emailSignup.templateContainer = $('.js-email-signup', context);
      site.emailSignup.initEmailSignup(context);
    }
  };
})(jQuery);
